import { createApp } from 'vue'
import App from './App.vue'
import Vant, { Lazyload } from 'vant'
import  'vant/lib/index.css'
// rem单位 1rem=100px
import './../public/rem.js'
import { router } from './router/index';
import pinia from '@/store/index';

// 引入全局样式文件
import '@/assets/scss/index.scss'

createApp(App).use(Vant).use(Lazyload).use(pinia).use(router).mount('#app')
