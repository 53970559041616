<template>
	<appHeader />
	<router-view :key="$route.fullPath"/>
	<div class="copyright">
        <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备2022010396号-2 微旅通信息技术（北京）有限公司</a>
    </div>
</template>

<script>
import appHeader from '@/components/appHeader.vue';
export default {
	name: 'App',
	components: {
		appHeader
	}
}
</script>

<style lang="scss">
.copyright{
	background: $color-text-d;
	display: flex;
	justify-content: center;
	border-top: 1rem solid $color-text-l;
	padding: 20rem;
	font-size: $font-size-medium-x;
	a{
		color: #B7B7B7;
	}
}
</style>
