import { defineStore } from 'pinia';

export const useEnglishDataStore = defineStore('englishData', {
    state() {
      return {
        englishData: []
      }
    },
    actions: {
      setEnglishData(data) {
        this.englishData = data;
      }
    },
});
