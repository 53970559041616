export async function trackEvent(category, action, label, valuejson, eventid, startTime) {
    var value = formatValue(valuejson)
    if (startTime) {
        value = "耗时:" + (new Date().getTime() - startTime) + "^" + value
    }
    if (typeof _tcTraObj == "undefined") {
        setTimeout(function () {
            _tcTraObj._tcTrackEventNew('h5艺龙酒店官网', '移动端-' + action,'h5官网-'+ label, value, eventid);

        }, 200)

    } else {
        _tcTraObj._tcTrackEventNew('h5艺龙酒店官网', '移动端-' + action,'h5官网-'+ label, value, eventid);
    }
}

function formatValue(value) {
    var str = ""
    if (value) {
        for (var key in value) {
            if (value[key]) {
                str += key + ":" + value[key] + "^"
            }
        }
    }
    return str
}