export const TOKEN = '_TOKEN_'; // token
export const ERR_OK = 'ok'; // 错误码
export const CHANNEL = 'channel'
export const RECOMMEND_Id = 'recommendId'
export const CHANNELCODE = {
  "official": 0,
  "Etv": 1,
  "elonghotel": 2,
  "ejm": 3,
  "ekf": 4,
  "edt": 5,
}
