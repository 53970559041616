<template>
  <van-sticky>
    <div class="header-wrap">
      <img :src="require('@/assets/img/logo.png')" />
      <div v-if="$route.path !== '/foreignPage'" class="tabs-warp">
        <div class="title" @click="toHomePage">首页</div>
        <van-dropdown-menu>
          <van-dropdown-item ref="companyRef" title="酒管公司">
            <ul class="company-wrap">
              <li
                v-for="item in companys"
                :key="item"
                @click="toHotelCompany(item)"
              >
                {{ item.companyName }}
              </li>
            </ul>
          </van-dropdown-item>
        </van-dropdown-menu>
        <van-dropdown-menu>
          <van-dropdown-item ref="assignPlatRef" title="赋能平台">
            <ul class="company-wrap">
              <li
                v-for="item in assignPlats"
                :key="item"
                @click="toAssignPlat(item)"
              >
                {{ item.enableCompanyName }}
              </li>
            </ul>
          </van-dropdown-item>
        </van-dropdown-menu>
        <div class="title" @click="toAssignPlat({ id: 1 })">会员体系</div>
        <div class="title" @click="toBrandNew">品牌资讯</div>
        <div class="title" @click="toAboutUs">关于我们</div>
        <div class="title" @click="toJoinUs">加入我们</div>
        <div class="title" @click="englishJump">English</div>
      </div>
      <div
        v-if="$route.path == '/foreignPage'"
        class="tabs-warp"
        style="width: 500rem; height: 100rem"
      >
        <div
          v-for="(item, index) in englishData"
          :key="index"
          class="title"
          @click="toEnglish(item)"
        >
          {{ item.name }}
        </div>
        <div class="title chinaVer" style="margin-left: auto" @click="toHome()">
          中文
        </div>
      </div>
    </div>
  </van-sticky>
</template>

<script setup>
import { getAssignPlat, getCompany } from "@/api/index";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { CHANNEL, RECOMMEND_Id } from "@/utils/constants";
import { getSessionStorage } from "@/utils/storage";
import { useEnglishDataStore } from "@/store/englishData";
import { storeToRefs } from "pinia";
import { trackEvent } from "@/utils/tracklog.js";

const store = useEnglishDataStore();
const { englishData } = storeToRefs(store);

const companys = ref([]);
const assignPlats = ref([]);
const companyRef = ref(null);
const assignPlatRef = ref(null);
onMounted(() => {
  Promise.all([
    getCompany().then((data) => {
      companys.value = data;
    }),
    getAssignPlat().then((data) => {
      assignPlats.value = data.filter((item) => item.id !== 1);
    }),
  ]);
});
const router = useRouter();
const toHomePage = () => {
  router.push("/index");
};
const toHotelCompany = ({ id, companyName }) => {
  //埋点 酒馆公司
  trackEvent(
    `顶栏-酒管公司-${companyName}选项`,
    "点击",
    `顶栏-酒管公司-${companyName}选项`,
    { path: `/hotel/${id}` },
    0,
    new Date().getTime()
  );
  router.push(`/hotel/${id}`);
  companyRef.value.toggle();
};
const toAssignPlat = ({ id, enableCompanyName }) => {
  //埋点 业务中台
  trackEvent(
    `顶栏-业务中台-${enableCompanyName}选项`,
    "点击",
    `顶栏-业务中台-${enableCompanyName}选项`,
    { path: `/assignPlatform/${id}` },
    0,
    new Date().getTime()
  );
  router.push(`/assignPlatform/${id}`);
  assignPlatRef.value.toggle();
};
const toBrandNew = () => {
  router.push("/brandNews");
};
const toAboutUs = () => {
  router.push("/aboutUs");
};
const toJoinUs = () => {
  router.push({
    path: "/joinUs",
    query: {
      channel: getSessionStorage(CHANNEL) || "official",
      recommendId: getSessionStorage(RECOMMEND_Id) || "",
    },
  });
};

const englishJump = () => {
  router.push({
    path: "/foreignPage",
  });
};

const toEnglish = (item) => {
  router.push({
    path: "/foreignPage",
    query: {
      id: item.id,
    },
  });
};

const toHome = () => {
  router.push("/index");
};
</script>

<style lang="scss" scoped>
.header-wrap {
  display: flex;
  align-items: center;
  padding: 20rem 15rem 0;
  background: $color-text-d;
  img {
    width: 80rem;
    height: 80rem;
  }
  .tabs-warp {
    display: flex;
    align-items: center;
    overflow-x: scroll;
    -webkit-scrollbar {
      display: none !important;
    }
    ::v-deep .van-dropdown-menu__bar {
      box-shadow: none;
      background: none;
    }
    ::v-deep .van-ellipsis {
      font-size: 26rem;
      color: #fff;
    }
    ::v-deep .van-dropdown-menu {
      margin-right: 20rem;
    }
    .title {
      font-size: 26rem;
      padding: 0 20rem;
      white-space: nowrap;
      color: #fff;
    }
  }
  .company-wrap {
    display: flex;
    flex-wrap: wrap;
    background: $color-text-d;
    padding: 20rem 0;
    li {
      width: 25%;
      text-align: center;
      color: #fff;
      font-size: 24rem;
      line-height: 50rem;
    }
  }
}

.chinaVer {
  position: absolute;
  right: 10px;
}
</style>
