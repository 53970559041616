import http from '@/utils/request';

// 获取首页数据
export const getIndexPageData = () => {
    return http.post('/bm/front/index/getIndexData', {});
};

// 获取酒管公司
export const getCompany = () => {
    return http.post('/bm/front/company/getConfigCompanys', {})
}

// 获取公司详情
export const getCompanyDetail = (id) => {
    return http.post(`/bm/front/company/getConfigCompanyById/${id}`, {})
}

// 获取赋能平台
export const getAssignPlat = () => {
    return http.post('/bm/front/enableCompany/getEnableConfigedCompanys', {})
}

// 获取赋能平台详情
export const getAssignPlatDetail = (id) => {
    return http.post(`/bm/front/enableCompany/getEnableConfigedCompanyById/${id}`, {})
}

// 获取资讯类型
export const getNewTypes = () =>{
    return http.post('/bm/front/info/getInfoTypes', {})
}

// 获取资讯详情
export const getNewDetails = (id) => {
    return http.post(`bm/front/info/loadData/${id}`, {})
}

// 获取品牌资讯
export const getBrandNew = (data) => {
    return http.post('/bm/front/info/getInfoData', data)
}

// 获取省份
export const getProvince = (data) => {
    return http.post('/sys/front/province/getProvinces', data)
}

// 获取城市
export const getCity = (equalProvinceId) => {
    return http.post('/sys/front/city/getCity', { equalProvinceId })
}

// 获取区县
export const getArea = (equalCityId) => {
    return http.post('/sys/front/district/getDistricts', { equalCityId })
}

// 获取品牌
export const getBrand = (equalCompanyId) => {
    if(!equalCompanyId) return
    return http.post('/sys/front/brand/getBrand', { equalCompanyId })
}

// 添加项目
export const addProject = (data) => {
    return http.post('/sys/front/projectReserve/add', data)
}

// 加入我们-职位列表
export const getJobList = (data) => {
    return http.post('/bm/front/recruit/datagrid', data)
}

// 加入我们-职位详情
export const getJobDetail = (id) => {
    return http.post(`/bm/front/recruit/detail/${id}`)
}

// 加入我们-投递简历
export const submitJoin = (data) => {
    return http.post(`/bm/front/jobSeeker/add`, data)
}

export const getEnglishData = (data) => {
    return http.post(`/bm/front/indexEn/getIndexEnVos`, data)
  }