import storage from 'good-storage';

export function setStorage(key, data){
  return storage.set(key, data)
}

export function getStorage(key){
  return storage.get(key)
}

export function clearStorage() {
  storage.clear();
}

export function setSessionStorage(key, data) {
  return storage.session.set(key, data)
}

export function getSessionStorage(key){
  return storage.session.get(key)
}