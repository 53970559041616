export function handleGetParams(url, params) {
    let finPath = url || '';
    const paramsArr = []; // 参数数组

    for (let key in params) {
        paramsArr.push(`${key}=${params[key]}`);
    }

    finPath = `${finPath}?${paramsArr.join("&")}`;

    return finPath;
}