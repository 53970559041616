import { createRouter, createWebHashHistory } from 'vue-router'

const Index = () => import('@/views/index'/* webpackChunkName: "index" */)
const Hotel = () => import('@/views/hotel'/* webpackChunkName: "hotel" */)
const AssignPlatform = () => import('@/views/assignPlatform'/* webpackChunkName: "assignPlatform" */)
const BrandName = () => import('@/views/brandNews'/* webpackChunkName: "brandName" */)
const AboutUs = () => import('@/views/aboutUs'/* webpackChunkName: "aboutUs" */)
const newsDetail = () => import('@/views/newsDetail'/* webpackChunkName: "aboutUs" */)
const Join = () => import('@/views/join'/* webpackChunkName: "join" */)
const JobDetail = () => import('@/views/jobDetail'/* webpackChunkName: "jobDetail" */)
const SendJob = () => import('@/views/sendJob'/* webpackChunkName: "sendJob" */)
const ForeignPage = () => import('@/views/foreignPage')

const routes = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/index',
    name: 'index',
    meta: {
      title: '系统首页'
    },
    component: Index,
  },
  {
    path: '/hotel/:id',
    component: Hotel,
  },
  {
    path: '/assignPlatform/:id',
    component: AssignPlatform,
  },
  {
    path: '/brandNews',
    component: BrandName,
  },
  {
    path: '/aboutUs',
    component: AboutUs
  },
  {
    path: '/newsDetail',
    component: newsDetail
  },
  {
    path: '/elongUnit/:id',
    component: AssignPlatform,
  },
  {
    path: '/joinUs',
    component: Join
  },
  {
    path: '/joinUs/detail/:id',
    component: JobDetail
  },
  {
    path: '/join/send/:id',
    component: SendJob
  },
  {
    path: '/foreignPage',
    component: ForeignPage
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export {
  router
}
