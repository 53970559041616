import axios from 'axios';
import { ERR_OK } from './constants';
const baseURL = process.env.NODE_ENV === 'production' ? 'https://official.elonghotel.com' : 'http://official.qa.elonghotel.com';
import NProgress from 'nprogress';
// import { getStorage } from './storage';
import { handleGetParams } from './utils';
import { Notify } from 'vant';

axios.defaults.timeout = 20 * 1000;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.baseURL = baseURL;

axios.interceptors.request.use(config=>{
    // config.headers['token'] = getStorage(TOKEN);
    return config;
}, error=>{
    return error;
})

// 响应拦截
axios.interceptors.response.use(res => {
    return res;
});

const http = {
    get: (url, params) => {
        return new Promise((resolve, reject) => {
            NProgress.start();
            if (params) {
                url = handleGetParams(url, params);
            }
            axios.get(url).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
                // ElMessage.error(error.msg || '发生了一个未知错误～');
            }).finally(() => {
                NProgress.done();
            });
        })
    },
    post: (url, params) => {
        return new Promise((resolve, reject) => {
            NProgress.start();
            axios.post(url, JSON.stringify(params)).then(res => {
                console.log(res, 12345)
                if (res.data.code === ERR_OK) {
                    resolve(res.data.data)
                } else {
                    Notify({ type: 'danger', message: res.data.msg });
                }
            }).catch(error => {
                Notify({ type: 'danger', message: error.msg || '发生了一个未知错误～' });
                reject(error);
            }).finally(() => {
                NProgress.done();
            });
        });
    }
}

export default http;
